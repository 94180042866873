import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueMeta from "vue-meta";
import "shared-components/scss/theme.scss";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMeta);

new Vue({
  router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
