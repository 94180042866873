<template>
  <div style="height: 100%">
    <slot name="header">
      <PhHeader>
        <template v-slot:userProfile>
          <slot name="userProfile"></slot>
        </template>
      </PhHeader>
    </slot>
    <main>
      <slot />
    </main>
    <slot name="footer">
      <PhFooter></PhFooter>
    </slot>
  </div>
</template>

<script>
import PhHeader from "./PhHeader";
import PhFooter from "./PhFooter";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "Layout",
  components: {
    PhHeader,
    PhFooter,
  },
})
export default class Layout extends Vue {}
</script>

<style lang="scss" scoped>
main {
  height: calc(100vh - 6rem);
  overflow-y: auto;
}
</style>
