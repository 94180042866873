<template>
  <footer class="d-flex footer"></footer>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "PhFooter",
  components: {},
})
export default class PhFooter extends Vue {}
</script>

<style lang="scss"></style>
