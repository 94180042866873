<template>
  <div :class="$options.name">
    <layout>
      <router-view></router-view>
    </layout>
  </div>
</template>

<script>
import Layout from "shared-components/components/layout/Layout";
import Component from "vue-class-component";
import Vue from "vue";

@Component({
  name: "App",
  components: {
    Layout,
  },
  metaInfo: {
    titleTemplate: "Avantect | %s",
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
@import "shared-components/scss/colors";

.App {
  background: $bs-dark-bg-blue;
  min-height: 100vh;
}
</style>
