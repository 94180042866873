




















































































































































import Component from "vue-class-component";
import { SvgProps } from "./common/SvgProps";

@Component({
  name: "AvantectLogo",
})
export default class AvantectLogo extends SvgProps {
  defaultWidth = 90;
  defaultHeight = 71;
  heightToWidth = 0.5;

  get getWidth(): number {
    if (this.width) return this.width;
    else if (!this.width && this.height)
      return Math.round(this.height / this.heightToWidth);
    return this.defaultWidth;
  }

  get getHeight(): number {
    if (this.height) return this.height;
    else if (!this.height && this.width)
      return Math.round(this.width * this.heightToWidth);
    return this.defaultHeight;
  }
}
