<template functional>
  <div :class="`${$options.name} d-flex  px-6`">
    <div class="py-2">
      <!--https://github.com/vuejs/vue/issues/7492#issuecomment-646289272-->
      <component
        :is="$options.components.AvantectLogo"
        :width="240"
        :height="90"
      />
    </div>
    <div class="py-5" style="margin-left: auto; margin-right=10px;">
      <slot name="userProfile"></slot>
    </div>
  </div>
</template>

<script>
import AvantectLogo from "../icons/AvantectLogo";

export default {
  name: "PhHeader",
  functional: true,
  components: { AvantectLogo },
};
</script>

<style lang="scss">
@import "../../scss/colors";
.PhHeader {
  background: $bs-dark-bg-blue;
  height: 6rem;
  box-shadow: $box-shadow;
}
</style>
